import { CustomerSearchType } from "@app/models/CustomerSearch";
import { MenuOption } from "@app/models/MenuOption";

export const roleIds = {
    ROLE_ADMIN: 'ADMIN',
    ROLE_CORPORATE_ADMIN: 'CORPORATE_ADMIN',
    ROLE_CORPORATE_SUPERVISOR: 'CORPORATE_SUPERVISOR',
    ROLE_CORPORATE_AGENT: 'CORPORATE_AGENT',
    ROLE_THIRDPARTY_SUPERVISOR: 'THIRDPARTY_SUPERVISOR',
    ROLE_THIRDPARTY_AGENT: 'THIRDPARTY_AGENT',
}

export const accountOrigins : Map<string, string> = new Map([
    ['microsoft.com', 'Usuario Corporativo'],
    ['password', 'Usuario externo a mi compañía']
]);

export const ticketPriorities : Map<string, string> = new Map([
    ['default','Normal'],
    ['low', 'Medio'],
    ['high', 'Alta'],
    ['critical', 'Muy Alta']
]);

export const ticketSla : Map<string, string> = new Map([
    ['on_time', 'A tiempo'],
    ['regular', 'Por vencer'],
    ['out_of_time',' Vencido']
])

export const ticketStatusForStyle : Map<string, string> = new Map([
    ['Pendiente', '1'],
    ['En revisión', '2'],
    ['Réplica del cliente', '3'],
    ['Procesado', '4'],
    ['Sin respuesta del cliente', '5'],
    ['Finalizada', '11'],
    ['Pendiente', '9'],
    ['En revisión', '10'],
    ['Anulado', '12'],
]);

export const noTicketStatusInFilters : number[] = [
    6, // Finalizada
    7, // Anulada,
    12,
    11
];

export const ticketStatusExcludedInMyTicketAssignedMe : number[] = [
    6, //Finalizada
    12,
    11
]

export const nroRecontactsInFilters : Map<string, string> = new Map([
    ['1', '1 recontacto'],
    ['2', '2 recontactos'],
    ['3', '3 recontacto'],
    ['4', '4 recontacto'],
    ['5', '5 recontacto'],
    ['6-10', 'De 6 a 10 recontactos'],
    ['11-15', 'De 11 a 15 recontactos'],
    ['16+', 'De 16 a más recontactos'],
]);

export const dateTypeInFilters : Map<string, string> = new Map([
    ['assignmentDate', 'Fecha de asignación'],
    ['creationDate', 'Fecha de creación'],
]);

export const organizationsForColor : Map<string, string> = new Map([
    ['AGORA', '#02B1FF'],
    ['AGORA_CLUB', '#F2007C'],
    ['AGORA_PAY', '#00CAFF'],
    ['AGORA_SHOP', '#FF6000'],
    ['AGORA_AHORRA', '#001FFE'],
    ['MAKRO', '#FFE500'],
    ['TPSA', '#FF4949'],
    ['PVEA', '#FF4949'],
    ['HPSA', '#F59237'],
    ['RPLAZA', '#7B61FF'],
    ['SHPSTAR', '#0970CC'],
    ['VIV', '#168D7F'],
    ['JOKR', '#5FF0AF'],
    ['MERKAO', '#3534BB'],
]);

export const optionsNewTicket: Map<string, string> = new Map([
    ['REGULAR', 'Asociado a cliente/pedido'],
    ['INTERN', 'Incidencias Internas'],
]);

export const codeGraphTitle : Map<string, string> = new Map([
    ['STORE_SUMMARY', 'Tiendas con más tickets abiertos'],
    ['RESOLUTOR_SUMMARY', 'Resolutores con más tickets abiertos'],
    ['ELAPSED_DAYS_SUMMARY', 'Días transcurridos desde la creación'],
    ['ELAPSED_DAYS', 'Días transcurridos desde la creación - Resumen'],
    ['SUBREASON_SUMMARY', 'Ranking de submotivos'],
    ['TEAM_SUMMARY', 'Equipos con más tickets abiertos'],
    ['PRIORITY_SUMMARY', 'Distribución tickets abiertos según prioridad'],
    ['SLA_SUMMARY', 'Distribución tickets abiertos según SLA'],
    ['OPENED_TICKETS', 'Días transcurridos desde la creación - Resumen'],
    ['RECONTACT_SUMMARY', 'Tickets abiertos según recontactos'],
    ['SUBAREA_SUMMARY', 'Subáreas con más tickets abiertos'],
    ['AREA_SUMMARY', 'Áreas con más tickets abiertos']
]);

export const monthEnEs : Map<string, string> = new Map([
    ['enero', 'January'],
    ['febrero', 'February'],
    ['marzo', 'March'],
    ['abril', 'April'],
    ['mayo', 'May'],
    ['junio', 'June'],
    ['julio', 'July'],
    ['agosto', 'August'],
    ['setiembre', 'September'],
    ['octubre', 'October'],
    ['noviembre', 'November'],
    ['diciembre', 'December'],
])
//20609309190
export const regexTypeData : Map<string, RegExp> = new Map([
    ['email', new RegExp(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z]{2,3}?$/)],
    ['id', new RegExp(/^\d{8,12}?$/)],//dni|pasaporte|ruc(11)-carnet de extranjeria(12)
    ['incidence', new RegExp(/^(PVEA|VIV|RPLAZA|HPSA|TPSA|AGORA|MKR|AGORA_SHOP|JOKR|SHPSTAR|JOKR|MERKAO)-(?:(?:W?[A-Za-z0-9]+)-(\d{6})-(\d{4})|(?:INC|CD|ST)-\d{8,10}-[0-9]+)$/)],
    ['order', new RegExp(/^((\d{13}-\d{2})|(\d{15})|(v\d+viv-\d{2})|(v\d+plzv-\d{2}|(v\d+pvqa-\d{2})|(v\d+vivqa-\d{2})))?$/)],
    ['sale-note', new RegExp(/^\d{13}?$/)]
]);

export enum CatalogType {
    TICKET_STATUS = 'TICKET_STATUS',
    USER_TEAMS = 'USER_TEAMS',
    USER_STORES = 'USER_STORES',
    USER_RESOLUTORS = 'USER_RESOLUTORS',
    ORGANIZATIONS = 'ORGANIZATIONS',
    TENANTS = "TENANTS",
    REASONS = 'REASONS',
    SUB_REASONS = 'SUB_REASONS',
    SELLERS = 'SELLERS',
    ROLES = 'ROLES',
    STORES = 'STORES',
    STORE_TYPES = 'STORE_TYPES',
    TEAMS = 'TEAMS',
    SLA = 'SLA',
    DATETYPE = 'DATETYPE',
    PRIORITY = 'PRIORITY',
    RECONTACTS = 'RECONTACTS',
    TAGS = 'TAGS',
    TICKET_SUBREASON_TYPE = 'TICKET_SUBREASON_TYPE',
    DIVISION_TYPES = 'DIVISION_TYPES',
    DELIVERY_MODES = 'DELIVERY_MODES',
    AREAS = 'AREAS',
    SUB_AREAS = 'SUB_AREAS',
    TICKET_TYPES = 'TICKET_TYPES',
    SOURCE_MEDIUM = 'SOURCE_MEDIUM'
}

export const sourceMedium: Map<string, string> = new Map([
    ['EMAIL', 'Correo Electrónico'],
    ['SOCIAL_NETWORK', 'Redes Sociales'],
    ['PAPER_FORM', 'LDR físico'],
    ['WEB_FORM', 'LDR web'],
    ['QR_FORM', 'LDR QR'],
    ['CUSTOMER_EMAIL', 'Correo electrónico'],
    ['CUSTOMER_ADDRESS', 'Domicilio']
]);

export const searchTypeMap: Map<string, CustomerSearchType> = new Map([
  ['email', CustomerSearchType.EMAIL],
  ['id', CustomerSearchType.IDENTITY_DOCUMENT],
  ['incidence', CustomerSearchType.TICKET_NUMBER],
  ['order', CustomerSearchType.ORDER_ID],
  ['sale-note', CustomerSearchType.SALE_NOTE_NUMBER],
]);

export interface RecordInfo {
  label: string;
  displayField: string;
}
export const assignmentFormLabelMap: Map<string, string> = new Map([
  ['dispatchTenantId', 'Despachado por'],
  ['sellTenantId', 'Vendido por'],
  ['ticketReasonId', 'Motivo'],
  ['ticketSubReasonId', 'Submotivo'],
  ['ticketSubReasonType', 'Tipo de Submotivo'],
  ['ticketStoreType', 'Tienda'],
  ['originType', 'Tipo de origen'],
  ['originCode', 'Origen'],
  ['deliveryMode', 'Método de entrega'],
  ['withdrawStoreCode', 'Tienda de retiro'],
  ['divisionType', 'Tipo de división'],
  ['registerOrganizationId', 'Empresa de registro'],
  ['registerStoreCode', 'Tienda de registro'],
  ['registerAreaId', 'Área de registro'],
  ['registerSubAreaId', 'Subárea de registro'],
]);

export const assignmentRecordMap: Map<string, RecordInfo> = new Map([
  ['dispatchTenantId', {label: 'Despachado por',  displayField: 'dispatchTenantName'}],
  ['sellTenantId', {label: 'Vendido por',  displayField: 'sellTenantName'}],
  ['ticketReasonId', {label: 'Motivo',  displayField: 'ticketReasonName'}],
  ['ticketSubReasonId', {label: 'Submotivo',  displayField: 'ticketSubReasonName'}],
  ['ticketSubReasonType', {label: 'Tipo de Submotivo',  displayField: 'ticketSubReasonTypeDescription'}],
  ['ticketStoreType', { label: 'Tienda',  displayField: 'ticketStoreTypeName'}],
  ['originType', {label: 'Tipo de origen',  displayField: 'originTypeName'}],
  ['originCode', {label: 'Origen',  displayField: 'originName'}],
  ['deliveryMode', {label: 'Método de entrega',  displayField: 'deliveryModeName'}],
  ['withdrawStoreCode', {label: 'Tienda de retiro',  displayField: 'withdrawStoreName'}],
  ['divisionType', {label: 'Tipo de división',  displayField: 'divisionType'}],
  ['registerOrganizationId', {label: 'Empresa de registro',  displayField: 'registerOrganizationName'}],
  ['registerStoreCode', {label: 'Tienda de registro',  displayField: 'registerStoreName'}],
  ['registerAreaId', {label: 'Área de registro',  displayField: 'registerAreaName'}],
  ['registerSubAreaId', {label: 'Subárea de registro',  displayField: 'registerSubAreaName'}],
]);

export const ticketTypeMenuOptions: MenuOption[] = [
    {
        label: "Incidencia",
        value: "INCIDENCE",
        icon: {
            name: "INCIDENCE",
            cssClass: ["ticket-icon"],
            isSVG: true,
        },
    },
    {
        label: "Libro de Reclamaciones",
        value: "LDR",
        icon: {
            name: "LDR",
            cssClass: ["ticket-icon"],
            isSVG: true,
        },
    },
];

const tenantNames = new Map([
    ['HPSA', 'Promart'],
    ['TPSA', 'Oechsle'],
    ['PVEA', 'plazaVea'],
    ['AGORA', 'Agora.pe'],
    ['RPLAZA', 'Real Plaza'],
    ['AGORA_SHOP', 'Agora Shop'],
    ['SHPSTAR', 'Shopstar'],
    ['MKR', 'Makro'],
    ['JOKR', 'Jokr'],
    ['MERKAO', 'Merkao'],
])

const sellerLDRNames = new Map([
    ['Promart', 'HPSA'],
    ['Oechsle', 'TPSA'],
    ['plazaVea', 'PVEA'],
])

const sellerNames = new Map([
    ['Promart', 'HPSA'],
    ['Oechsle', 'TPSA'],
    ['plazaVea', 'PVEA'],
    ['Vivanda', 'VIV'],
    ['Makro', 'MKR'],
    ['Jokr', 'JOKR'],
    ['Merkao', 'MERKAO'],
])

const subReasonTypeName = new Map([
    ['ORDER', 'Pedido'],
    ['CLAIM', 'Reclamo'],
    ['CONSULTATION', 'Consulta'],
    ['INTERNAL_FINDING', 'Hallazgo Interno'],
    ['NONE', 'Ninguno']
])

const issueType = new Map([
    ['SERVICE', 'Servicio'],
    ['PRODUCT', 'Producto'],
])

const requestType = new Map([
    ['CLAIM', 'Reclamo'],
    ['COMPLAINT', 'Queja'],
])



export const commons = {
    adminRoles : [roleIds.ROLE_ADMIN, roleIds.ROLE_CORPORATE_ADMIN],
    supervisorRoles : [roleIds.ROLE_CORPORATE_SUPERVISOR],
    accountOrigins : accountOrigins,
    ticketPriorities : ticketPriorities,
    ticketSla : ticketSla,
    ticketStatusForStyle : ticketStatusForStyle,
    codeGraphTitle: codeGraphTitle,
    monthEnEs: monthEnEs,
    organizationsForColor: organizationsForColor,
    regexTypeData: regexTypeData,
    noTicketStatusInFilters: noTicketStatusInFilters,
    ticketStatusExcludedInMyTicketAssignedMe: ticketStatusExcludedInMyTicketAssignedMe,
    nroRecontactsInFilters: nroRecontactsInFilters,
    dateTypeInFilters: dateTypeInFilters,
    optionsNewTicket: optionsNewTicket,
    catalogType: CatalogType,
    searchTypeMap: searchTypeMap,
    subReasonTypeName,
    sourceMedium: sourceMedium, 
    tenantNames,
    sellerNames,
    sellerLDRNames,
    assignmentRecordMap,
    issueType,
    requestType
}
